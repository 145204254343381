import React, { Component } from 'react';
import {
    Switch,
    Route
} from 'react-router-dom';

import Home from '../pages/home/home';
import Shop from '../pages/shop/shop';
import ShopDetail from '../pages/shop-detail/shop-detail';
import Past from '../pages/past/past';
import Future from '../pages/future/future';
import Payment from '../pages/payment/payment';
import ScrollToTop from '../components/scroll/scroll';

class Routes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			section: null,
			isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
		}
	}

    componentDidMount() {
		this.fetchData();
	}

	handleSection(section) {
		this.setState({
			section: section
		})
	}

	async fetchData() {
		let fetchedData = {};

		let fetchHome = await fetch('https://dacal-cms.herokuapp.com/home').then(value => {
			return value;
		});

		let fetchFooter = await fetch('https://dacal-cms.herokuapp.com/footer').then(value => {
			return value;
		});

		let fetchPast= await fetch('https://dacal-cms.herokuapp.com/past').then(value => {
			return value;
		});

		let fetchFuture = await fetch('https://dacal-cms.herokuapp.com/future').then(value => {
			return value;
		});

		let fetchCategories = await fetch('https://dacal-cms.herokuapp.com/categories').then(value => {
			return value;
		});

		fetchedData.home = await fetchHome.json().then(data => {
			return {
				landing: [
					{
						title: data.landing.landing_image_1.title,
						description: data.landing.landing_image_1.description,
						image: `${data.landing.landing_image_1.image.url}`,	
					},
					{
						title: data.landing.landing_image_2.title,
						description: data.landing.landing_image_2.description,
						image: `${data.landing.landing_image_2.image.url}`,	
					},
					{
						title: data.landing.landing_image_3.title,
						description: data.landing.landing_image_3.description,
						image: `${data.landing.landing_image_3.image.url}`,	
					}
				],
				banners: {
					banner_1: {
						text: data.banner_section.banner_image_1.text,
						description: data.banner_section.banner_image_1.description,
						image: `${data.banner_section.banner_image_1.image.url}`
					},
					banner_2: {
						text: data.banner_section.banner_image_2.text,
						description: data.banner_section.banner_image_2.description,
						image: `${data.banner_section.banner_image_2.image.url}`
					},
					hashtags: [
						data.banner_section.hashtags.hashtag_1,
						data.banner_section.hashtags.hashtag_2,
						data.banner_section.hashtags.hashtag_3,
					],
					text: data.banner_section.text,
					banner_lead: {
						text: data.banner_section.banner_image_3.text,
						image: `${data.banner_section.banner_image_3.image.url}`
					},
				}
			};
		});

		fetchedData.footer = await fetchFooter.json().then(data => {
			return data;
		});

		fetchedData.past = await fetchPast.json().then(data => {
			return data;
		});

		fetchedData.future = await fetchFuture.json().then(data => {
			return data;
		});

		fetchedData.categories = await fetchCategories.json().then(data => {
			let dataCategories = {};
			let allProducts = [];
			let hasHighlightedCategory = data.find(category => category.highlight === true);
			let highlightedCategory = null;

			data.forEach(category => {
				category.products.forEach(product => allProducts.push(product));
				dataCategories[category.name] = category.products;
			});

			if (hasHighlightedCategory) {
				highlightedCategory = hasHighlightedCategory.name
			}

			return {
				dataCategories,
				allProducts,
				highlightedCategory
			};
		});

		this.setState({
			data: fetchedData
		})
	}

    render() {
		const favicon = window.location.href.includes('detalle/') ? "../media/favicon.svg" : "media/favicon.svg";
		
		if (!this.state.data) {
			return (
				<div className="loading-page">
					<img src={favicon} alt="Cargando..."></img>
				</div>
			)
		}

		// const userAgent = navigator.userAgent;

		// const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
		
        return (
			<Switch>
				<Route exact path="/tienda">
					<ScrollToTop />
						<Shop categories={this.state.data.categories} dataFooter={this.state.data.footer} section={this.state.section} isMobile={this.state.isMobile}/>
				</Route>
				<Route
					path="/detalle/:id"
					render={(props) => (
						<span>
							<ScrollToTop />
							<ShopDetail {...props} dataFooter={this.state.data.footer} isMobile={this.state.isMobile}/>
						</span>
					)}
				/>
				<Route exact path="/pasado">
					<ScrollToTop />
						<Past data={this.state.data.past} dataFooter={this.state.data.footer} isMobile={this.state.isMobile}/>
				</Route>
				<Route exact path="/futuro">
					<ScrollToTop />
					<Future data={this.state.data.future} dataFooter={this.state.data.footer} isMobile={this.state.isMobile}/>
				</Route>
				<Route exact path="/compra">
					<ScrollToTop />
					<Payment MPdata={this.state.selectedProduct} isMobile={this.state.isMobile}/>
				</Route>
				<Route exact path="/">
					<ScrollToTop />
					<Home highlightedCategory={this.state.data.categories.highlightedCategory} data={this.state.data.home} dataFooter={this.state.data.footer} onSectionChange={this.handleSection.bind(this)} isMobile={this.state.isMobile}/>
				</Route>

				<Route>
					<ScrollToTop />
					<Home data={this.state.data.home} dataFooter={this.state.data.footer} onSectionChange={this.handleSection.bind(this)} isMobile={this.state.isMobile}/>
				</Route>
			</Switch>
        );
    }
}

export default Routes;