import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App";

import 'bootstrap/dist/css/bootstrap.css';
import "./index.css";
 
ReactDOM.render(
  <App/>, 
  document.getElementById("root")
);