import React, { Component } from "react";

import "./menu.css";

import {
  Link
} from "react-router-dom";
 
class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInHome: props.isInHome,
            isDeep: props.isDeep
        }
    }

    getNav() {
        if(this.props.isMobile && !this.state.isInHome) {
            return (
                <div className="nav-links col-12 d-flex justify-content-between align-items-center">
                    <Link to="/pasado" className="left">propósito</Link>
                    <Link to="/tienda" className="left">tienda</Link>
                    <Link to="/futuro" className="left">comunidad</Link>
                </div>
            )
                
        }

        if(this.props.isMobile && this.state.isInHome) {
            return ''
        }
        
        if(!this.props.isMobile) {
            return (
                <div className="nav-links">
                    <Link to="/pasado" className="left">propósito</Link>
                    <Link to="/tienda" className="left">tienda</Link>
                    <Link to="/futuro" className="left">comunidad</Link>
                </div>
            )
        }
    }

    render() {
        const { isDeep } = this.state;

        return (
            <nav className={(this.props.isMobile ? 'justify-content-center flex-column' : 'justify-content-between') + ' d-flex align-items-center fixed-top'}>
				<Link to="/" className="nav-logo">
					<img src={(isDeep ? '../' : '') + 'media/logo-nuevo.svg'} alt="logo-dacal"/>
				</Link>
				{
                    this.getNav()
                }
			</nav>
        );
    }
}
 
export default Menu;