import "./payment.css";

import React, { Component } from "react";

import {
	Link
} from "react-router-dom";

class Payment extends Component {
	constructor(props) {
        super(props);
		this.state = {}
	}

	componentDidMount() {
		const data = localStorage.getItem('mpData');
		const MPdata = JSON.parse(data);

		if(MPdata !== null) {
			fetch(`https://dacal-cms.herokuapp.com/products/${Number(MPdata.product.id)}/${MPdata.to}`, {
				method: 'PUT',
				body: JSON.stringify({
					"buyer_email": MPdata.email,
					"buyer_phone": MPdata.phone,
					"buyer_inquiries": MPdata.inquiries,
					"stock_id" : MPdata.product.size_id,
					"fabric_id": MPdata.product.fabric_id
				}),
				headers:{
				  'Content-Type': 'application/json'
				}
			}).then(res => res.json());
		}
	}

	componentWillUnmount() {
		localStorage.removeItem('mpData');
	}

	render() {
		return (
			<div className="payment container-fluid d-flex justify-content-center align-items-center">
				<div className="text-center">
					<p className="mb-5">¡Gracias por elegirnos!, en breve nos comunicamos con vos.</p>
					<Link to="/tienda">Volver</Link>
				</div>
			</div>
		);
	}
}
 
export default Payment;