import "./shop-detail.css";

import React, { Component } from "react";

import {
	Link
} from "react-router-dom";

import Menu from "../../components/menu/menu";
import Footer from '../../components/footer/footer';
import DropdownFabrics from '../../components/dropdown/dropdown';
 
class ShopDetail extends Component {
	constructor(props) {
        super(props);
		this.state = {
			selected: this.props.match.params.id,
			product: {
				image_1: {
					url: ''
				},
				image_2: {
					url: ''
				},
				image_3: {
					url: ''
				},
				name: '',
				description: '',
				price: '',
				tag: {},
				stock: []
			},
			selectedSize: {},
			selectedFabric: {
				image: {
					url: ''
				},
				name: ''
			},
			dropdownFabrics: [],
			showModal: false,
			showCollapse: false,
			showCollapseSize: false,
			email: '',
			phone: '',
			inquiries: '',
			blockForm: true,
			loadPage: false,
			currentLeadImg: ''
		}

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleCollapseSize = this.toggleCollapseSize.bind(this);
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				loadPage: true
			})
		}, 1000);

		fetch(`https://dacal-cms.herokuapp.com/products/${this.state.selected}`).then(
			res => {
				res.json().then(async resJ => {
					await this.setState({
						product: resJ,
						currentLeadImg: resJ.image_1.url
					})

					this.setState({
						selectedSize: this.state.product.stock.length > 0 ? this.state.product.stock[0].size.size : '',
						selectedSize_id: this.state.product.stock.length > 0 ? this.state.product.stock[0].id : '',
						selectedFabric: this.state.product.stock.length > 0 ? this.state.product.stock[0].fabric_quantity[0].fabric : '',
						selectedFabric_id: this.state.product.stock.length > 0 ? this.state.product.stock[0].fabric_quantity[0].id : '',
					})

					this.parseFabrics();

					this.setState({
						dropdownFabrics: this.state.fabrics[this.state.selectedSize]
					})			
					
					if (this.state.product.on_demand !== true) {
						await this.addMPScript(resJ.data_preference_id);
						
						setTimeout(() => {
							const mpBtn = document.querySelector('.mercadopago-button');
							mpBtn.setAttribute('disabled', this.state.blockForm);
							mpBtn.addEventListener('click', this.handleMP.bind(this));
							mpBtn.style.display= 'inline-block';
							document.querySelector('.payment-buttons').appendChild(mpBtn);
						}, 1800)
					}
				});
			}
		);

	}

	addMPScript(id) {
		const scriptNode = document.createElement("script");
		scriptNode.setAttribute('data-preference-id', id);
		scriptNode.setAttribute('id', 'mepa-script');
		scriptNode.setAttribute('data-button-label', 'MERCADO PAGO');
		scriptNode.setAttribute('src', 'https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js');
		document.body.appendChild(scriptNode);
	}

	parseFabrics() {
		let fabrics = {};
		
		this.state.product.stock.forEach(item => {
			fabrics[item.size.size] = item.fabric_quantity
		})

		this.setState({
			fabrics: fabrics
		})
	}

	setSize(size, size_id) {
		this.setState({
			selectedSize: size,
			selectedSize_id: size_id,
			dropdownFabrics: this.state.fabrics[size],
			selectedFabric: this.state.fabrics[size][0].fabric,
			selectedFabric_id: this.state.fabrics[size][0].id,
		})
	}

	handleFabric(fabric) {
		this.setState({
			selectedFabric: fabric.fabric,
			selectedFabric_id: fabric.id
		})
	}

	toggleModal() {
        this.setState({ 
			showModal: !this.state.showModal
        }, () => {
			document.querySelector('#email').focus();
		})
	}

	toggleCollapse() {
        this.setState({ 
            showCollapse: !this.state.showCollapse
        })
	}

	toggleCollapseSize() {
        this.setState({ 
            showCollapseSize: !this.state.showCollapseSize
        })
	}
	
	putProduct(to) {
		this.toggleModal();

		const emailInfo = {
			"buyer_email": this.state.email,
			"buyer_phone": this.state.phone,
			"buyer_inquiries": this.state.inquiries
		}

		let data = emailInfo

		if (to !== 'demand') {
			data = {...emailInfo, ...{
				"stock_id" : this.state.selectedSize_id,
				"fabric_id": this.state.selectedFabric_id
			}}
		}

		fetch(`https://dacal-cms.herokuapp.com/products/${this.state.selected}/${to}`, {
			method: 'PUT',
			body: JSON.stringify(data),
			headers:{
			  'Content-Type': 'application/json'
			}
		}).then(res => res.json())
	}

	handleEmail(e) {
		const mpBtn = document.querySelector('.mercadopago-button');

		let dynamicKey = {
			[e.target.id]: e.target.value
		}

		this.setState(dynamicKey, () => {
			if (this.state.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) && this.state.phone) {
				this.setState({
					blockForm: false
				});
	
				if (mpBtn) {
					mpBtn.removeAttribute('disabled');
				}
			} else {
				this.setState({
					blockForm: true
				});
	
				if (mpBtn) {
					mpBtn.setAttribute('disabled', this.state.blockForm);
				}
			}
		})
	}

	getProductForm() {
		if (this.state.product.on_demand) {
			return ''
		}

		if (this.state.product.stock.length === 0) {
			return <div>
				<p>{this.props.dataFooter.purchase_information}</p>
				<p className="sin-stock">SIN STOCK</p>
			</div>
		}

		return (
			<div className="select-product-type">
				<p className="detail-size-label">TALLE</p>	
				<div className="form-check-inline mb-3">
					{
						this.state.product.stock.map((item, idx) =>
							<div className="size-box" key={idx}>
								<input className="form-check-input" type="radio" name="size" id={`size-${item.id}`} value={item.size.size} onChange={this.setSize.bind(this, item.size.size, item.id)} checked={item.size.size === this.state.selectedSize}/>
								<label className={(item.size.size === this.state.selectedSize ? 'full' : '') + ' form-check-label'} htmlFor={`size-${item.id}`}>
									{item.size.size}
								</label>
							</div>
						)
					}
				</div>
				<div className="size-table">
					<div id="accordionSize">
						<h5 className="mb-0">
							<button className="btn btn-link" onClick={this.toggleCollapseSize} data-toggle="collapse" data-target="#size-info" aria-expanded="true" aria-controls="size-info">
								Tabla de talles {(this.state.showCollapseSize ? '-' : '+')}
							</button>
						</h5>
						</div>

						<div id="size-info" className={(this.state.showCollapseSize ? 'show' : '') + ' collapse'} aria-labelledby="headingOne" data-parent="#accordionSize">
						<div className="payment-info-body">
							<span>Contorno de cadera</span>
							<ul className="list-unstyled">
								<li>A - 90CM / B - 100CM / C - 115CM</li>
							</ul>
						</div>
					</div>
				</div>
				<DropdownFabrics fabrics={this.state.dropdownFabrics} onFabricChange={this.handleFabric.bind(this)}></DropdownFabrics>
				<div className="selected-fabric align-items-center d-flex flex-row mb-3">
					<img className="zoom" src={this.state.selectedFabric.image.url} alt="imagen-tela"/>
					<span>{this.state.selectedFabric.name}</span>
				</div>
			</div>
		)	
	}

	toggleImages(e) {
		this.setState({
			currentLeadImg: e.target.src
		})

		e.target.src = document.querySelector('.lead-image').src;
	}

	handleMP() {
		localStorage.setItem('mpData', JSON.stringify({
			to: 'comprar',
			email: this.state.email,
			phone: this.state.phone,
			inquiries: this.state.inquiries,
			product: {
				id: this.state.selected,
				fabric_id: this.state.selectedFabric_id,
				size_id: this.state.selectedSize_id
			}
		}));
	}

	componentWillUnmount() {
		if(this.state.product.on_demand !== true) {
			const script = document.querySelector('#mepa-script');
			const button = document.querySelector('.mercadopago-button');
			const mepaWrapper = document.querySelector('.mp-mercadopago-checkout-wrapper');

			document.body.removeChild(script);
			document.querySelector('.payment-buttons').removeChild(button);

			if (mepaWrapper) {
				document.body.removeChild(mepaWrapper);
			}
		}
	}

	render() {
		if(!this.state.loadPage) {
			return (
				<div className="loading-page">
					<img src="../media/favicon.svg" alt="Cargando..."></img>
				</div>
			)
		}
			
		return (
			<div className="container-fluid">
				<header>
					<Menu isDeep="true" isMobile={this.props.isMobile}></Menu>
				</header>
				<main className="shop-detail margin-top">
					<div className="d-flex align-items-center justify-content-start categorie-title back">
						<Link to="/tienda">
							<h3>
								Volver
							</h3>
						</Link>
					</div>
					<div className="row sticky-wrapper">
						<div className="col-sm-12 col-md-8 p-0 scroll-images">
							<div className="row">
								<div className="col-sm-12 col-md-4 secondary-images d-flex flex-column justify-content-center">
									<img onClick={this.toggleImages.bind(this)} src={this.state.product.image_2.url} alt="detalle"/>
									<img onClick={this.toggleImages.bind(this)} src={this.state.product.image_3.url} alt="detalle"/>
								</div>
								<div className="col-sm-12 col-md-8 lead-image-w">
									<img className="lead-image" onClick={this.toggleImages.bind(this)} src={this.state.currentLeadImg} alt="detalle"/>
								</div>
							</div>
						</div>
						<div className={(this.state.isFixed ? 'is-fixed' : '') + (this.state.isAbsolute ? 'is-absolut' : '') + ' col-sm-12 col-md-4 p-0 fixed-info'}>
							<p className="detail-title"><strong>{this.state.product.name}</strong></p>
							<p className="detail-price">${this.state.product.price}</p>
							{
								this.getProductForm()		
							}
							<div className="row product-icons">
								<div className="product-description">
									<h4>DESCRIPCION DE LA PRENDA</h4>
									<p>{this.state.product.description}</p>
								</div>
								<div className="product-properties mb-3 mt-3 flex-column d-flex justify-content-center align-items-start">
									<div className="icon">
										<img src="../media/icons/fibra.svg" alt="prop-icon"/>
										<span className="propertie-value">fibra - <span className="ml-2">{this.state.product.tag.fiber}</span></span>
									</div>
									<div className="icon">
										<img src="../media/icons/peso.svg" alt="prop-icon"/>
										<span className="propertie-value">peso - <span className="ml-2">{this.state.product.tag.weight}</span></span>
									</div>
									<div className="icon">
										<img src="../media/icons/tiempo.svg" alt="prop-icon"/>
										<span className="propertie-value">tiempo - <span className="ml-2">{this.state.product.tag.time}</span></span>
									</div>
									<div className="icon">
										<img src="../media/icons/huella.svg" alt="prop-icon"/>
										<span className="propertie-value">huella de carbono - <span className="ml-2">{this.state.product.tag.carbon_footprint}</span></span>
									</div>
									<div className="icon">
										<img src="../media/icons/agua.svg" alt="prop-icon"/>
										<span className="propertie-value">huella de agua - <span className="ml-2">{this.state.product.tag.water_footprint}</span></span>
									</div>
								</div>
							</div>
							<div className="payment-info">
								<div id="accordion">
									<h5 className="mb-0">
										<button className="btn btn-link" onClick={this.toggleCollapse} data-toggle="collapse" data-target="#payment-info" aria-expanded="true" aria-controls="payment-info">
										Información útil para la compra {(this.state.showCollapse ? '-' : '+')}
										</button>
									</h5>
									</div>

									<div id="payment-info" className={(this.state.showCollapse ? 'show' : '') + ' collapse'} aria-labelledby="headingOne" data-parent="#accordion">
									<div className="payment-info-body">
										<p>A la hora de comprar vas a poder elegir pagar con Mercado Pago u otros medios de pago.</p>
										<p>Seleccionando <strong>Mercado Pago:</strong> se realiza el pago y luego nos comunicamos por mail para coordinar el envío, ya que trabajamos con una mensajería privada de confianza.</p>
										<p>Seleccionando <strong>Otros medios de pago:</strong> reservamos la prenda que selecciones y nos comunicamos directamente por mail para coordinar tanto el pago cómo la compra.</p>
										<p>* Para los productos a pedido se coordina todo por mail.</p>
										<p>Prestá atención en la correcta carga del mail!</p>
										<p>Cualquier inconveniente, nos encontrás en somosdacal@gmail.com</p>
										<br></br>
									</div>
								</div>
							</div>
							<button type="button" className="btn payment-button" onClick={this.toggleModal} data-toggle="modal" data-target="#paymentModal">
								{(this.state.product.on_demand) ? 'PEDIR' : 'COMPRAR'}
							</button>

							<div className={(this.state.showModal ? 'show' : '') + ' modal fade'} style={{display: (this.state.showModal ? 'block' : '')}} id="paymentModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="false">
							<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
								<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLongTitle">ELEGIR FORMA DE PAGO</h5>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true" onClick={this.toggleModal}>x</span>
									</button>
								</div>
								<div className="modal-body">
									<span>Ingresá tu correo y número de teléfono</span>
									<p className="text-muted">De esta forma nos podremos poner en contacto con vos, los campos correo y teléfono son obligatorios.</p>
									<form>
										<div className="form-group">
											<label htmlFor="email">Correo electrónico</label>
											<input type="email" className="form-control" id="email" aria-describedby="emailHelp" onChange={this.handleEmail.bind(this)} value={this.state.email} />
										</div>
										<div className="form-group">
											<label htmlFor="phone">Teléfono</label>
											<input type="phone" className="form-control" id="phone" onChange={this.handleEmail.bind(this)} value={this.state.phone} />
										</div>
										<div className="form-group">
											<label htmlFor="inquiries">Consultas</label>
											<textarea type="text" className="form-control" id="inquiries" onChange={this.handleEmail.bind(this)} value={this.state.inquiries} />
										</div>
										<small id="emailHelp" className="form-text text-muted">Nunca vamos a compartir tus datos con nadie más.</small>
									</form>
								</div>
								<div className="modal-footer">
									{
										this.state.product.on_demand 
											? (
												<Link to="/compra">
													<button type="button" className="btn payment-button" onClick={this.putProduct.bind(this, 'pedir')} disabled={this.state.blockForm}>Confirmar</button>
												</Link>
											)

											: (
												<div className="payment-buttons">
													<Link to="/compra">
														<button type="button" className="btn payment-button" onClick={this.putProduct.bind(this, 'reservar')} disabled={this.state.blockForm}>
															otras formas de pago
														</button>
													</Link>
												</div>
											)
									}
								</div>
								</div>
							</div>
							</div>
						</div>
					</div>
					<div className="recomendation d-flex justify-content-center align-items-center text-center">
						<img src="../media/icons/manos.svg" alt="prop-icon"/>
						<span>Recomendamos lavar a mano con agua fría y jabón neutro.</span>
						<img src="../media/icons/jabon.svg" alt="prop-icon"/>
					</div>
				</main>
				<Footer isDeep="true" data={this.props.dataFooter}></Footer>
			</div>
		);
	}
}
 
export default ShopDetail;