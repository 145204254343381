import "./past.css";

import React, { Component } from "react";

import Menu from "../../components/menu/menu"
import Footer from "../../components/footer/footer"
 
class Past extends Component {
  	render() {
   		return (
			<div className="container-fluid">
				<header className="home-header">
					<Menu isMobile={this.props.isMobile}></Menu>	
				</header>
				<main className="past margin-top">
					<div className="row d-flex justify-content-center">
						<div className="past-subtitle text-center">
							<h2>{this.props.data.title_1}</h2>
							<p className="mb-0">{this.props.data.text_1_a}</p>
							<p>{this.props.data.text_1_b}</p>
						</div>
						<div className="col-md-12 d-flex justify-content-center align-items-center past-home-image text-center">
							<img src={this.props.data.image_1.image.url} alt=""/>
						</div>
					</div>
					<div className="row d-flex justify-content-center past-subtitle-1">
						<div className="text-center d-flex flex-column justify-content-center past-video-header">
							<h2>{this.props.data.title_2}</h2>
							<p className="mb-0">{this.props.data.text_2_a}</p>
							<p>{this.props.data.text_2_b}</p>
						</div>	
						<div className="col-md-12 text-center p-0 past-video right">
							<div className="row">
								<div className={(!this.props.isMobile ? 'text-left' : '') + ' col-sm-12 col-md-6 text-left p-0 d-flex flex-column justify-content-center'}>
									<h3>{this.props.data.video_1.title}</h3>
									<p>{this.props.data.video_1.description}</p>
								</div>
								<div className="col-sm-12 col-md-6 p-0">
									<video src={this.props.data.video_1.video.url} muted controls autoPlay={!this.props.isMobile} loop>
										Tu navegador no admite el elemento <code>video</code>.
									</video>
								</div>
							</div>
						</div>
						<div className="col-md-12 text-center p-0 past-video left">
							<div className="row">
								<div className="col-sm-12 col-md-6 p-0">
									<video src={this.props.data.video_2.video.url} muted controls autoPlay={!this.props.isMobile} loop>
										Tu navegador no admite el elemento <code>video</code>.
									</video>
								</div>
								<div className={(!this.props.isMobile ? 'text-right' : '') + ' col-sm-12 col-md-6 text-right p-0 d-flex flex-column justify-content-center'}>
									<h3>{this.props.data.video_2.title}</h3>
									<p>{this.props.data.video_2.description}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row d-flex justify-content-center align-items-center flex-column past-data">
						<img src={`${this.props.data.metrics.image.url}`} alt=""/>
					</div>
				</main>
				<Footer data={this.props.dataFooter}></Footer>
			</div>
    	);
  	}
}
 
export default Past;