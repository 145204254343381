import React, { Component } from "react";

import "./shop-grid.css";

import {
  Link
} from "react-router-dom";
 
class ShopGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    makeGrid() {
        if (this.props.selected.items.length) {
            return this.props.selected.items.map((item, idx) =>
                (
                    <div className="shop-item text-center" key={idx}>
                        <Link to={`/detalle/${item.id}`}>
                            <img className="shop-grid-img-fade" 
                                src={item.image_1.url} alt="imagen shop"
                                onMouseOver={e => (e.currentTarget.src = item.image_2.url)}
                                onMouseLeave={e => (e.currentTarget.src = item.image_1.url)} />
                        </Link>
                        <p className="text-center">{item.name}</p>
                    </div>
                )
            )
        }

        return (
            <p>{this.props.noProductText}</p>
        )
    }

    render() {
        return (
            <div>
                <div className="d-flex align-items-center justify-content-center categorie-title">
                    <h3>
                        {this.props.selected.catName} 
                    </h3>
                </div>
                <div className="row shop-grid d-flex justify-content-center">
                    {this.makeGrid()}
                </div>
            </div>
        );
    }
}
 
export default ShopGrid;