import './home.css';

import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Menu from '../../components/menu/menu';
import Footer from '../../components/footer/footer';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    updateSection(section) {
        this.props.onSectionChange(section);
    }

    render() {
        const paths = ['/pasado', '/tienda', '/futuro'];

        return (
            <div className="container-fluid home">
                <header className="home-header">
                    <Menu isInHome="true" isMobile={this.props.isMobile}></Menu>
                </header>
                {this.props.isMobile ? (
                    <div className="nav-links-mobile margin-top d-flex flex-column align-items-center justify-content-around">
                        {this.props.data.landing.map((item, idx) => (
                            <div
                                key={idx}
                                className="d-flex justify-content-center align-items-center"
                            >
                                <Link to={paths[idx]}></Link>
                                <div className="home-mobile-info text-center">
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                </div>
                                <img src={item.image} alt="landing" />
                            </div>
                        ))}
                    </div>
                ) : (
                    <main className="justify-content-center margin-top">
                        <div className="row sections">
                            {this.props.data.landing.map((item, idx) => (
                                <div
                                    key={idx}
                                    className="col-4 section d-flex flex-column justify-content-center align-items-center text-center p-4"
                                >
                                    <Link to={paths[idx]}></Link>
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                    <img src={item.image} alt="landing" />
                                </div>
                            ))}
                        </div>
                        <div className="row banner banner-hash justify-content-center align-items-center">
                            <p>{this.props.data.banners.text}</p>
                        </div>
                        <div className="row banner justify-content-center align-items-center">
                            <Link
                                to="/tienda"
                                onClick={this.updateSection.bind(
                                    this,
                                    this.props.highlightedCategory
                                )}
                            ></Link>
                            <img
                                src={this.props.data.banners.banner_1.image}
                                alt="banner"
                            />
                            <div className="banner-desc text-center">
                                <p>{this.props.data.banners.banner_1.text}</p>
                                <p className="desc">
                                    {
                                        this.props.data.banners.banner_1
                                            .description
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="row banner justify-content-center align-items-center">
                            <Link to="/pasado"></Link>
                            <img
                                src={this.props.data.banners.banner_2.image}
                                alt="banner"
                            />
                            <div className="banner-desc text-center">
                                <p>{this.props.data.banners.banner_2.text}</p>
                                <p className="desc">
                                    {
                                        this.props.data.banners.banner_2
                                            .description
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="row banner banner-hash hash justify-content-around align-items-center">
                            {this.props.data.banners.hashtags.map(
                                (hash, idx) => (
                                    <p key={idx}>{hash}</p>
                                )
                            )}
                        </div>
                        <div className="row banner banner-tienda justify-content-center align-items-center text-white">
                            <Link to="/tienda"></Link>
                            <p>{this.props.data.banners.banner_lead.text}</p>
                            <img
                                src={this.props.data.banners.banner_lead.image}
                                alt="banner"
                            />
                        </div>
                    </main>
                )}
                <Footer data={this.props.dataFooter}></Footer>
            </div>
        );
    }
}

export default Home;
