import React, { Component } from "react";

import "./dropdown.css";
 
class DropdownFabrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected,
            showDrop: false 
        }

        this.toggleDrop = this.toggleDrop.bind(this);
    }

    sendFabric(fabric) {
        this.toggleDrop();
        this.props.onFabricChange(fabric)
    }

    toggleDrop() {
        this.setState({ 
            showDrop: !this.state.showDrop
        })
    }

    render() {
        const { showDrop } = this.state

        return (
            <div className="btn-group mb-3">
                <button type="button" className="fabric-btn dropdown-toggle text-left d-flex justify-content-between alig-items-center" onClick={this.toggleDrop} aria-expanded={showDrop}>
                    <span className="mr-3">CAMBIAR TELA</span>
                </button>
                <ul className={(showDrop ? 'show' : '') + ' dropdown-menu collapse navbar-collapse'}>
                    {
                        this.props.fabrics.map((fab, idx) => 
                            <li key={idx} onClick={this.sendFabric.bind(this, fab)} className="selected-fabric">
                                <img className="zoom" src={fab.fabric.image.url} alt="fabric"/>
                                <span>{fab.fabric.name}</span>
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }
}
 
export default DropdownFabrics;