import "./future.css";

import React, { Component } from "react";

import Menu from "../../components/menu/menu"
import Footer from "../../components/footer/footer"
 
class Future extends Component {
	render() {
		return (
			<div className="container-fluid">
				<header className="home-header">
					<Menu isMobile={this.props.isMobile}></Menu>	
				</header>
				<div className="row margin-top text-center d-flex justify-content-center align-items-center future-home-image">
					<img src={`${this.props.data.image_1.image.url}`} alt=""/>
				</div>
				<main className="future">
					<div className="col-md-12 text-center p-0 past-video right">
						<div className="row">
							<div className="col-md-6 text-left p-0 d-flex flex-column justify-content-center">
								<h3>{this.props.data.image_2.title}</h3>
								<p>{this.props.data.image_2.text}</p>
							</div>
							<div className="col-md-6 p-0">
								<img src={`${this.props.data.image_2.image.url}`} alt=""/>
							</div>
						</div>
					</div>
					<div className="col-md-12 text-center p-0 past-video left">
						<div className="row">
							<div className="col-md-6 p-0">
								<img src={this.props.data.image_3.image.url} alt=""/>
							</div>
							<div className="col-md-6 text-right p-0 d-flex flex-column justify-content-center">
								<h3>{this.props.data.image_3.title}</h3>
								<p>{this.props.data.image_3.text}</p>
							</div>
						</div>
					</div>
					<div className="row future-row d-flex justify-content-center future-data">
						<div className="text-center d-flex flex-column justify-content-center past-video-header">
							<h2>{this.props.data.title_2}</h2>
							<p>{this.props.data.text_2}</p>
						</div>
						{
							this.props.data.videos_1.map((video, idx) =>
							<div key={idx} className="col-md-4 p-0 text-center video-container">
								<p className="video-title">{video.title}</p>
								<video src={`${video.video.url}`} muted controls poster={`${video.video_image.url}`}>
									Tu navegador no admite el elemento <code>video</code>.
								</video>
								<p className="video-desc">{video.description}</p>
							</div>
							)
						}
					</div>
					<div className="row future-row d-flex justify-content-center future-data">
						<div className="text-center d-flex flex-column justify-content-center past-video-header">
							<h2>{this.props.data.title_3}</h2>
							<p>{this.props.data.text_3}</p>
						</div>
						{
							this.props.data.videos_2.map((video, idx) =>
							<div key={idx} className="col-md-4 p-0 text-center video-container">
								<p className="video-title">{video.title}</p>
								<video src={`${video.video.url}`} muted controls poster={`${video.video_image.url}`}>
									Tu navegador no admite el elemento <code>video</code>.
								</video>
								<p className="video-desc">{video.description}</p>
							</div>
							)
						}
					</div>
				</main>
				<Footer data={this.props.dataFooter}></Footer>
			</div>
		);
	}
}
 
export default Future;