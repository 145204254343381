import './shop.css';

import React, { Component } from 'react';

import ShopGrid from '../../components/shop-grid/shop-grid';
import Footer from '../../components/footer/footer';
import Menu from '../../components/menu/menu';

class Shop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: props.categories.dataCategories,
            selected: {
                catName:
                    props.section === null
                        ? 'TODOS LOS PRODUCTOS'
                        : props.section,
                items:
                    props.section === null
                        ? props.categories.allProducts
                        : props.categories.dataCategories[props.section],
            },
        };
    }

    changeCategorie(cat) {
        this.setState({
            selected: {
                catName: cat,
                items: this.state.categories[cat],
            },
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <header className="general-header">
                    <Menu isMobile={this.props.isMobile}></Menu>
                    <div className="categories d-flex justify-content-around align-items-center margin-top">
                        {Object.keys(this.props.categories.dataCategories).map(
                            (cat, idx) => (
                                <p
                                    key={idx}
                                    onClick={this.changeCategorie.bind(
                                        this,
                                        cat
                                    )}
                                >
                                    {cat}
                                </p>
                            )
                        )}
                    </div>
                </header>
                <main className="shop justify-content-center shop">
                    <ShopGrid
                        selected={this.state.selected}
                        noProductText={this.props.dataFooter.no_products_text}
                    ></ShopGrid>
                </main>
                <Footer data={this.props.dataFooter}></Footer>
            </div>
        );
    }
}

export default Shop;
