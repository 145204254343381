import React, { Component } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import "./footer.css";
 
class Footer extends Component {
	constructor(props) {
        super(props);
        this.state = {
			isDeep: props.isDeep
		}
	}
	
    render() {
		const { isDeep } = this.state;
		const year = new Date().getFullYear();
		
        return (
            <footer className="justify-content-center align-items-center text-center">
                <p className="shop-info mb-5">{this.props.data.text}</p>
                <div className="row footer-info justify-content-center align-items-center text-center">
					<div className="col-sm-12 col-md-3">
						<img src={(isDeep ? '../' : '') + 'media/icons/credito.svg'} alt=""/>

						<strong><p>Medios de pago</p></strong>
						<p>{this.props.data.text_icon_1}</p>
					</div>
					<div className="col-sm-12 col-md-3">
						<img src={(isDeep ? '../' : '') + 'media/icons/correo.svg'} alt=""/>

						<strong><p>Consultanos</p></strong>
						<p>{this.props.data.text_icon_2}</p>
					</div>
					<div className="col-sm-12 col-md-3">
						<img src={(isDeep ? '../' : '') + 'media/icons/cambios.svg'} alt=""/>

						<strong><p>Plazos de cambio</p></strong>
						<p>{this.props.data.text_icon_3}</p>
					</div>
				</div>
                <div className="row media justify-content-center align-items-center">
					<div className="text-center">
						<a href="https://www.instagram.com/somosdacal/" target="_blank" rel="noreferrer">
							<FontAwesomeIcon className="mr-3" icon={faInstagram} />
						</a>
						<a href="https://www.facebook.com/somosdacal" target="_blank" rel="noreferrer">
							<FontAwesomeIcon className="mr-3" icon={faFacebook} />
						</a>
						<a href="https://wa.link/p7j0if" target="_blank" rel="noreferrer">
							<FontAwesomeIcon className="mr-3" icon={faWhatsapp} />
						</a>
						<span className="m-0">Somos Dacal - {year}</span>
						<p className="mt-3 mb-0 col-12">Argentina</p>
					</div>
				</div>
            </footer>
        );
    }
}
 
export default Footer;